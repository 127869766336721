import { Box, Card, Grid, Stack, Typography } from '@mui/material'
import React from 'react';
import { accreditedLogoList, airlinesLogoList } from '../../Assests/assets';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import "./styles/airlines_slider.css";

function AirlinesList() {

  const { t } = useTranslation();
  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1
      }
    },
    
  }
    
  const item = {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1
    },
    
  }

  return (
    <Box sx={{px: {xs: 0}}}>
        <Typography
            component={motion.h3} 
            initial={{
                opacity: 0,
                y: 50
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.1,
                  damping: 2
                }
            }}  
            sx={{
                textAlign: "center",
                py: 2, 
                fontWeight: "bold"
            }} 
            variant='h3' 
            color="primary.main"
        >
          {t("strategicDirections")}
        </Typography>
        {/* <Typography
          component={motion.h3} 
          initial={{
              opacity: 0,
              y: 50
          }}
          whileInView={{
              opacity: 1,
              y: 0,
              transition: {
              delay: 0.1,
              damping: 2
              }
          }}
        sx={{fontWeight: "bold",  textAlign: "center",}} 
        variant='body2'
      >{t("areYouReadyToDiscoverTheWorld")}</Typography> */}
        <Box 
          sx={{
            px: {xs: 1, sm: 15}, 
            py: 5, 
            // border: "1px solid black"
          }}
          component={motion.div} 
          variants={container}
          initial="hidden"
          whileInView="visible"
        >
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} columnSpacing={15}>
            {accreditedLogoList.map((data, index) => (
              <Grid item xs={12} sm={4} md={4} key={index}>
                <Box 
                  component={motion.div}
                  variants={item}
                  whileHover={{
                    y: -5,
                    boxShadow: "0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.02), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.01), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.02)"
                  }}
                  sx={{
                    height: "auto",
                    p: "10px", 
                    width: "auto",
                    cursor: "pointer",
                    borderRadius: 3,

                  }}
                >
                    <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={data} alt="logo" />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* <Box 
          sx={{
            px: {xs: 0, sm: 15}, 
            py: 5, 
            // border: "1px solid black"
          }}
          component={motion.div} 
          variants={container}
          initial="hidden"
          whileInView="visible"
        >
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} columnSpacing={15}>
            {airlinesLogoList.map((data) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Box 
                  component={motion.div}
                  variants={item}
                  whileHover={{
                    y: -5,
                    boxShadow: "0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.02), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.01), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.02)"
                  }}
                  sx={{
                    height: "auto",
                    p: "10px", 
                    width: "auto",
                    cursor: "pointer",
                    borderRadius: 3,

                  }}
                >
                    <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={data} alt="logo" />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box> */}
        
          {/* <Box
            component={motion.div}
            sx={{
              border: "1px solid black",
              p: 3,
            }}
            initial={{
                x: 2000
            }}
            
            animate={{
                x: -3000,
                transition: {
                    duration: 10,
                    repeat: Infinity
                }
            }}
          >
            <Grid sx={{ display: "flex", flexDirection: "row", gap: 10 }} xs sm md >

              {airlinesLogoList.map((data, index) => (
                  <Grid item xs sm md key={index}>

                    <Box 
                      component={motion.div}
                      variants={item}
                      whileHover={{
                        y: -5,
                        boxShadow: "0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.02), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.01), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.02)"
                      }}
                      sx={{
                        height: "auto",
                        p: "10px", 
                        width: "auto",
                        cursor: "pointer",
                        borderRadius: 3,
                        // border: "1px solid red"
                      }}
                    >
                        <img style={{height: "200%", width: "200%", objectFit: "cover"}} src={data} alt="logo" />
                    </Box>
                  </Grid>
              ))}
            </Grid>
          </Box> */}

        <Typography
            component={motion.h3} 
            initial={{
                opacity: 0,
                y: 50
            }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.1,
                  damping: 2
                }
            }}  
            sx={{
                textAlign: "center",
                py: 2, 
                fontWeight: "bold"
            }} 
            variant='h3' 
            color="primary.main"
        >
          {t("officialPartners")}
        </Typography>

        <Stack className='airlines-slide' py={5} px={{xs: 1, md: 15}}>
          <Splide options={{ 
            rewind: true, 
            type: 'loop',
            autoPlay     : true,
            pauseOnHover : false,
            resetProgress: false,
            // drag: 'free',
            focus: 'center',
            gap: "2rem",
            perPage: 5,
            arrows: false,
            pagination: false,
            breakpoints: {
              600 : { 
                perPage: 2,
                gap: "1rem" 
              },
            },
            autoScroll: {
              speed: 1,

            },
          }} 
          // extensions={{ AutoScroll }}
          
          >
            {airlinesLogoList.map((data, index) => (
              <SplideSlide  
                key={index}
              >
                  <Box 
                    component={motion.div}
                    // variants={item}
                    whileHover={{
                      y: -5,
                      boxShadow: "0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.02), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.01), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.02)"
                    }}
                    sx={{
                      height: "auto",
                      p: "10px", 
                      width: "200px",
                      cursor: "pointer",
                      borderRadius: 3,
                      // border: "1px solid red"
                    }}
                  >
                      <img style={{height: "100%", width: "100%", objectFit: "cover"}} src={data} alt="logo" />
                  </Box>
              </SplideSlide>
            ))}
          </Splide>
        </Stack>
        </Box>
  )
}

export default AirlinesList