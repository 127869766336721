import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { BlueButton, InputField } from '../../Lib/MuiThemes/MuiComponents'
import PhoneInput from 'react-phone-input-2';
import "./css/style.css";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Axios } from '../../Lib/Axios/AxiosConfig';
import useSnackBar from '../../Lib/CustomHooks/useSnackBar';
import { backgroundPatternImg } from '../../Assests/assets';

function ContactUs() {

  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(false);
  const { showSnackBar } = useSnackBar();
  const [ contactUsData, setContactUsData ] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: ""
  });
  const [ validateData, setValidateData ] = useState({
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    message: null
  });

  const handleChanges = (e) => {
    let { name, value } = e.target;
    setValidateData(prev => ({...prev, [name]: null}))
    setContactUsData(prev => ({...prev, [name]: value}))
  }

  function isDataValidated(){
    let hasError = false;

    if(contactUsData.firstname === "" || contactUsData.firstname.length > 50){
        hasError = true;
        setValidateData(prev => ({...prev, firstname: t("Please enter a valid firstname")}))
    }

    if(contactUsData.lastname === "" || contactUsData.lastname.length > 50){
        hasError = true;
        setValidateData(prev => ({...prev, lastname: t("Please enter a valid lastname")}))
    }

    if(contactUsData.email === "" || contactUsData.email.length > 70 || !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(contactUsData.email)){
        hasError = true;
        setValidateData(prev => ({...prev, email: t("Please enter a valid email")}))
    }

    if(contactUsData.phone === ""){
        hasError = true;
        setValidateData(prev => ({...prev, phone: t("Please enter a valid phone number")}))
    }

    if(contactUsData.message === "" || contactUsData.message.length > 200){
        hasError = true;
        setValidateData(prev => ({...prev, message: t("Message field is required and should not exceed more than 200 characters")}))
    }

    return hasError;
  }

  async function submitContactUsForm(){
    if(!isDataValidated()){
        try{
            setLoading(true);
            const response = await Axios({
                method: "post",
                url: `contactus`,
                data: {
                    "email": contactUsData.email,
                    "firstName": contactUsData.firstname,
                    "lastName": contactUsData.lastname,
                    "number": contactUsData.phone,
                    "message": contactUsData.message
                },
                maxBodyLength: Infinity,
                validateStatus: function (status) {
                    if(status >= 400){
                        showSnackBar("error", `Error: ${status}, ${t("Error happened while submitting the form, Please try again")}`);   
                        setLoading(false);
                        throw new Error("Error");
                    }
                    return status;
                }
            });

            if(response.status === 200){
                showSnackBar("success", t("We received your Query, We will get in touch within 24 hours")); 
                setContactUsData(prev => ({
                    ...prev,
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    message: ""
                }))
                setLoading(false);
            }
        }catch(error){
            console.log(error);
            showSnackBar("error", t("Error happened while submitting the form, Please try again")); 
            setLoading(false);
        }
    }
  }

  return (
    <Box 
        sx={{
            height: "100%",
            display: "flex",
            px: {xs: 1, md: 0},
            py: {xs: 1, md: 1},
            justifyContent: {xs: "start", md: "center"},
            flexDirection: "column",
            background: `none, url('${backgroundPatternImg}')`,
            backgroundRepeat: "repeat",
            backgroundSize: "700px", 
            // border: "1px solid black"
        }}
    >
        <Stack textAlign="center">
            <Typography 
                component={motion.h3} 
                initial={{
                    opacity: 0,
                    y: 50
                }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                    delay: 0.1,
                    damping: 2
                    }
                }}
                sx={{py: 2, fontWeight: "bold"}} 
                variant='h3' 
                color="primary.main"
            >
                {t("contactUs")}
            </Typography>
        </Stack>
        <Grid container spacing={2} px={{xs: 1, md: "25%"}}>
            <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                    <Typography sx={{fontWeight: "bold"}} variant='h4' color="primary.main">{t("france")}</Typography>
                    <Typography variant='h6' color="text.main">55, Rue Louis Blanc, 75010-Paris, <br /> France.</Typography>
                    <Typography
                        href="tel:+330142097000" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        +33 (0) 1 42 09 70 00
                    </Typography>
                    <Typography
                        href="mailto: paris@errancesvoyages.com" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        paris@errancesvoyages.com
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                    <Typography sx={{fontWeight: "bold"}} variant='h4' color="primary.main">{t("france")}</Typography>
                    <Typography variant='h6' color="text.main">23, Avenue Paul Vaillant - Couturier, 93120 - La Courneuve</Typography>
                    <Typography
                        href="tel:+330142097000" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        +33 (0) 1 42 09 70 00
                    </Typography>
                    <Typography
                        href="mailto: paris@errancesvoyages.com" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        paris@errancesvoyages.com
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                    <Typography sx={{fontWeight: "bold"}} variant='h4' color="primary.main">{t("india")}</Typography>
                    <Typography variant='h6' color="text.main">No.205, Mission Street (Opp. Nilgiris), <br /> Pondicherry-605001.</Typography>
                    <Typography
                        href="tel:+04132228600" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        0413 - 222 86 00
                    </Typography>
                    <Typography 
                        href="mailto: pondy@errancesvoyages.com" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        pondy@errancesvoyages.com
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                    <Typography sx={{fontWeight: "bold"}} variant='h4' color="primary.main">{t("SRI LANKA")}</Typography>
                    <Typography variant='h6' color="text.main">342, K.K.S. Road, Jaffna - Sri Lanka</Typography>
                    <br />
                    <Typography
                        href="tel:+94 (21) 222 0889" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        +94 (21) 222 0889
                    </Typography>
                    <Typography
                        href="mailto: jaffna@errancesvoyages.com" 
                        component="a" 
                        variant='h6' 
                        color="red"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        jaffna@errancesvoyages.com
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
        {/* <Stack direction={{xs: "column", md: "row"}} p={3} gap={{xs: 2, md: 10}} justifyContent="space-between" px={{xs: 1, md: "25%"}}>
            
            
            
        </Stack> */}
        <Stack 
            p={3}
            alignItems="center"
            justifyContent="center"
        >
            <Stack 
                p={3}
                spacing={4} 
                borderRadius={3} 
                backgroundColor="#F8F6FD"
                // border="1px solid black"
                width={{xs: "100%", md: "50%"}}
                height="100%"
            >
                
                <Stack direction="row" alignItems="center" justifyContent={"center"}>
                    <Typography 
                        variant='h4' 
                        color="text.main" 
                        fontWeight="bold"
                        textAlign="center"
                    >
                        {t("connectWithUs")}
                    </Typography>
                </Stack>
                <Stack direction={{xs: "column", md: "row"}} spacing={5}>
                    <Stack spacing={1} width="100%">
                        <InputField
                            // required
                            size='small'
                            fullWidth
                            placeholder={t("firstname")}
                            name="firstname"
                            type='text'
                            value={contactUsData.firstname}
                            onChange={handleChanges}
                            error={validateData.firstname}
                        />
                        {validateData.firstname && <Typography variant='subtitle1' color="error">{validateData.firstname}</Typography>}
                    </Stack>
                   <Stack spacing={1} width="100%">
                    <InputField
                            // required
                            fullWidth
                            size='small'
                            placeholder={t("lastname")}
                            name="lastname"
                            type='text'
                            value={contactUsData.lastname}
                            onChange={handleChanges}
                            error={validateData.lastname}

                        />
                        {validateData.lastname && <Typography variant='subtitle1' color="error">{validateData.lastname}</Typography>}
                   </Stack>
                    
                </Stack>
                <Stack direction={{xs: "column", md: "row"}} spacing={5}>
                    <Stack spacing={1} width="100%">
                        <PhoneInput
                            specialLabel=''
                            country={'fr'}
                            inputStyle={{
                                borderColor: validateData.phone && "red",
                                backgroundColor: "#fff",
                                height: 38
                            }}
                            // {...props}
                            fullWidth
                            placeholder={t("phoneNumber")}
                            type='text'
                            name="phone"
                            value={contactUsData.phone}
                            onChange={(num) => {
                                setValidateData(prev => ({...prev, phone: null}))
                                setContactUsData(prev => ({...prev, phone: num}))
                            }}
                        />
                        {validateData.phone && <Typography variant='subtitle1' color="error">{validateData.phone}</Typography>}

                    </Stack>
                    <Stack spacing={1} width="100%">
                        <InputField
                            // required
                            size='small'
                            fullWidth
                            placeholder={t("email")}
                            name="email"
                            type='text'
                            value={contactUsData.email}
                            onChange={handleChanges}
                            error={validateData.email}

                        />
                        {validateData.email && <Typography variant='subtitle1' color="error">{validateData.email}</Typography>}

                    </Stack>
                </Stack>
                <Stack spacing={1} width="100%">
                    <InputField
                        // required
                        size='small'
                        multiline
                        rows={5}
                        fullWidth
                        placeholder={t("typeYourMessageHere")}
                        type='text'
                        name="message"
                        value={contactUsData.message}
                        onChange={handleChanges}
                        error={validateData.message}

                    />
                    {validateData.message && <Typography variant='subtitle1' color="error">{validateData.message}</Typography>}

                </Stack>
                <BlueButton 
                    type='submit'
                    onClick={submitContactUsForm}
                    loading={loading}
                >{t("submit")}</BlueButton>
            </Stack>
        </Stack>
    </Box>
  )
}

export default ContactUs